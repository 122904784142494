.hover-zoom {
  overflow: hidden;
}

.hover-zoom img {
  transition: all 0.3s ease 0s;
  width: 100%;
}

.hover-zoom img:hover {
  transform: scale(1.25);
}

.checkout-card-body{ 
  scale: 1;
}

.checkout-card-body:hover {
  transform: scale(1.01);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.fa-trash-alt{ 
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.fa-trash-alt:hover {
  color: rgb(65, 128, 70);  
}


.checkbox-wrapper-47 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}




.checkbox-wrapper-47 label {
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: black;
  font-weight: 500;
  margin: 5px;
}

.checkbox-wrapper-47 label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #928c8c;
  border-radius: 6px;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label {
  padding-left: 1em;
  color: #0f5229;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label:before {
  top: 0;
  width: 100%;
  height: 2em;
  border-color: #2cbc63;
}

.checkbox-wrapper-47 label,
.checkbox-wrapper-47 label::before {
  transition: 0.25s all ease;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-input button {
  background-color: #f8f9fa;
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.quantity-input button:hover {
  background-color: #e9ecef;
}

.quantity-input input[type="number"] {
  width: 50px;
  text-align: center;
}
/* CSS to style the scrollbar */
/* Works on Chrome, Edge, and Safari */

/* The container with the scrollbar */
.overflow-auto::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle on the scrollbar */
.overflow-auto::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Color of the scrollbar handle on hover */
}

/* Track */
.overflow-auto::-webkit-scrollbar-track {
  background: #f2f2f2; /* Color of the scrollbar track */
}

/* Track on hover */
.overflow-auto::-webkit-scrollbar-track:hover {
  background: #ddd; /* Color of the scrollbar track on hover */
}

@media screen and (max-width: 768px) {
  .card .card-body .rounded-3 {
    display: none; /* Hide the image */
  }
  .card-body h5,
  .card-body p,
  .card-body .fw-normal {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .card-body .fw-normal {
    font-weight: normal; /* Ensure font weight is normal */
  }
}
